export enum RoutesSummaryEnum {
  home = 'home',
  solutions = 'solutions',
  about = 'about',
  pillars = 'pillars',
  contact = 'contact',
}

export enum RoutesFormattedSummaryEnum {
  home = 'Home',
  solutions = 'Soluções',
  about = 'Sobre a leap',
  pillars = 'Pilares de atuação',
  contact = 'Contato',
}

export enum RoutesEnum {
  home = "/home",
  about = "/sobre",
  solutions = "/solucoes",
  contact = "/contato",
  digital_assessment = "/digital-assessment",
  digital_blueprint = "/digital-blueprint",
  performance_racking = "/performance-racking",
  evolution_lifecycle = "/evolution-lifecycle",
  success_cases = "/cases-de-sucesso",
}

export enum RoutesFormattedEnum {
  home = 'Home',
  solutions = 'Soluções',
  about = 'Sobre a leap',
  contact = 'Contato',
  digital_assessment = 'Digital Assessment',
  digital_blueprint = 'Digital Blueprint',
  performance_racking = 'Performance Racking',
}
