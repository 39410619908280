export enum PillarsSubmenuEnum {
  digital_assessment = "digital_assessment",
  digital_blueprint = "digital_blueprint",
  performance_racking = "performance_racking",
  evolution_lifecycle = "evolution_lifecycle",
}

export enum PillarsSubmenuFormattedEnum {
  digital_assessment = "Digital assessment",
  digital_blueprint = "Digital blueprint",
  performance_racking = "Performance racking",
  evolution_lifecycle = "Evolution Lifecycle",
}
