export const colors = {
  background: "rgb(22, 22, 22)",
  grey: "#252525",
  black: "rgb(0, 0, 0)",
  green: "rgb(103, 237, 53)",
  light_green: "#35ED8B",
  text_white: "rgb(255,255,255)",
  blue: "#394CFF",
  light_blue: "rgb(13,153,255)",
  purple: "rgb(119,131,245)",
};
