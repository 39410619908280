/* eslint-disable import/no-extraneous-dependencies */

import { useRouter } from 'next/router';
import Head from 'next/head';
import { AppProps } from 'next/app';

import { ThemeProvider } from 'styled-components';
import { LeapProviders } from '~/providers';
import { Header } from '~/components/header';

import { GlobalStyle } from '../global-styles';
import homeBg from '../../public/home-background.png';
import { Footer } from "~/components/footer";
import { FC, useEffect } from "react";
import { IFCWithChildren } from "~/interfaces/i-fc-with-children";

/**
 * @todo
 * - ajustar o layout geral para scroll effects
 * - aplicar o efeito background na tela home
 * - ajustar as imagens das sections na tela de soluções
 * - ajustar a imagem de "conceito de leap frogging" na tela de sobre
 *
 * - tela de digital assessment
 * - tela de digital blueprint
 * - tela de performance racking
 */

const ComponentWithRedirectTop: IFCWithChildren = ({ children }) => {
  const router = useRouter();
  useEffect(() => {
    const root = document.getElementById("root");
    root.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [router]);

  return <>{children}</>;
};

function LeapSPA({ pageProps, Component }: AppProps): JSX.Element {
  const router = useRouter();

  if (
    typeof window !== "undefined" &&
    window.location.href.substr(0, 5) !== "https" &&
    !window.location.href.includes("localhost")
  ) {
    window.location.href = window.location.href.replace("http", "https");
  }

  return (
    <>
      <Head>
        <link rel="manifest" href="/manifest.json" />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />

        <meta name="theme-color" content="#ffffff" />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />

        <script async src="https://js.stripe.com/v3/pricing-table.js" />

        <title>Leap</title>
      </Head>

      <ThemeProvider theme={{}}>
        <GlobalStyle />
        <LeapProviders>
          <div
            id="root"
            {...(router.asPath.replace("/", "") === "home" && {
              style: {
                backgroundImage: `url(${homeBg.src})`,
                backgroundSize: "cover",
              },
            })}
          >
            <Header />
            <ComponentWithRedirectTop>
              <Component {...pageProps} />
            </ComponentWithRedirectTop>
            <Footer />
          </div>
        </LeapProviders>
      </ThemeProvider>
    </>
  );
}

export default LeapSPA;
