import { IFCWithChildren } from '~/interfaces/i-fc-with-children';

import {
  BaseTypographH1,
  BaseTypographH2,
  BaseTypographH3,
  BaseTypographSpan,
  BaseTypographP,
  BaseTypographSmall,
} from './styles';
import { useState } from "react";

interface ITypographProps {
  readonly size: number;
  readonly letterSpacing?: number;
  readonly lineHeight?: number;
  readonly color?: string;
  readonly bold?: boolean;
  readonly underline?: boolean;
  readonly italic?: boolean;
  readonly opacity?: number;
  readonly uppercase?: boolean;
  readonly lowercase?: boolean;
  readonly weak?: boolean;
  readonly pointer?: boolean;
  readonly hoverColor?: string;
  readonly onClick?: () => void;
}

const TypographH1: IFCWithChildren<ITypographProps> = (props) => {
  const [hover, setHover] = useState<boolean>(false);

  const { onClick, children, ...styles } = props;
  return (
    <BaseTypographH1
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      applyHoverColor={hover && !!props.hoverColor}
      {...styles}
      {...(onClick && {
        onClick,
      })}
    >
      {children}
    </BaseTypographH1>
  );
};

const TypographH2: IFCWithChildren<ITypographProps> = (props) => {
  const { onClick, children, ...styles } = props;
  const [hover, setHover] = useState<boolean>(false);

  return (
    <BaseTypographH2
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      applyHoverColor={hover && !!props.hoverColor}
      {...styles}
      {...(onClick && {
        onClick,
      })}
    >
      {children}
    </BaseTypographH2>
  );
};

const TypographH3: IFCWithChildren<ITypographProps> = (props) => {
  const { onClick, children, ...styles } = props;
  const [hover, setHover] = useState<boolean>(false);

  return (
    <BaseTypographH3
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      applyHoverColor={hover && !!props.hoverColor}
      {...styles}
      {...(onClick && {
        onClick,
      })}
    >
      {children}
    </BaseTypographH3>
  );
};

const TypographSpan: IFCWithChildren<ITypographProps> = (props) => {
  const { onClick, children, ...styles } = props;
  const [hover, setHover] = useState<boolean>(false);

  return (
    <BaseTypographSpan
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      applyHoverColor={hover && !!props.hoverColor}
      {...styles}
      {...(onClick && {
        onClick,
      })}
    >
      {children}
    </BaseTypographSpan>
  );
};

const TypographP: IFCWithChildren<ITypographProps> = (props) => {
  const { onClick, children, ...styles } = props;
  const [hover, setHover] = useState<boolean>(false);

  return (
    <BaseTypographP
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      applyHoverColor={hover && !!props.hoverColor}
      {...styles}
      {...(onClick && {
        onClick,
      })}
    >
      {children}
    </BaseTypographP>
  );
};

const TypographSmall: IFCWithChildren<ITypographProps> = (props) => {
  const { onClick, children, ...styles } = props;
  const [hover, setHover] = useState<boolean>(false);

  return (
    <BaseTypographSmall
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      applyHoverColor={hover && !!props.hoverColor}
      {...styles}
      {...(onClick && {
        onClick,
      })}
    >
      {children}
    </BaseTypographSmall>
  );
};

export {
  TypographH1,
  TypographH2,
  TypographH3,
  TypographSpan,
  TypographP,
  TypographSmall,
};
